import React from 'react'
import { Link } from 'gatsby'

export default function BookshelfRollover({ currentImage, pathTo }) {
    return (
        <div className="bookshelf-rollover">
            <Link to={pathTo}>
                <img src={currentImage} />
            </Link>
        </div>
    )
}
