import React, {useState} from 'react'
import { Link } from 'gatsby'
import MothButtons from '../../components/MothButtons'
import SEO from '../../components/seo'
import "../layout.css"
import BookshelfRollover from '../../components/bookshelfRollover'

import adRo from "../../images/rollovers/graphic-design/ad-ro.png"
import bookCoverRo from "../../images/rollovers/graphic-design/bookcover-ro.png"
import foundStagesRo from "../../images/rollovers/graphic-design/foundstages-ro.png"
import logoRo from "../../images/rollovers/graphic-design/logo-ro.png"
import magazineRo from "../../images/rollovers/graphic-design/magazine-ro.png"
import miscRo from "../../images/rollovers/graphic-design/misc-ro.png"
import newspaperRo from "../../images/rollovers/graphic-design/newspaperro.png"
import photogRo from "../../images/rollovers/graphic-design/photog-ro.png"
import posterRo from "../../images/rollovers/graphic-design/poster-ro.png"
import websiteRo from "../../images/rollovers/graphic-design/website-ro.png"
import woaRo from "../../images/rollovers/graphic-design/woa-ro.png"

export default function Writing() {

    let [thing, setThing] = useState(null)
    let [pathTo, setPathTo] = useState("")

    let doThing = (img) => {
        setThing(img)
    }

    let doOtherThing = () => {
        setThing(null)
    }

    let doMobileThing = (img, path) => {
        doThing(img)
        setPathTo(path)
    }

    const MobileBookshelf = () => {
        return (
            <div className="hide-over-720">
                <div id="mobile-bookshelf" className="bookshelf">
                    <div className="book-links-wrapper">
                        <div id="misc-link" className="book-link" onClick={() => doMobileThing(miscRo, "misc")} />
                        <div id="woa-link" className="book-link" onClick={() => doMobileThing(woaRo, "world-of-anarchie")} />
                        <div id="found-stages-link" className="book-link" onClick={() => doMobileThing(foundStagesRo, "found-stages")} />
                        <div id="advertisements-link" className="book-link" onClick={() => doMobileThing(adRo, "advertisements")} />
                        <div id="logos-link" className="book-link" onClick={() => doMobileThing(logoRo, "logos")} />
                        <div id="web-design-link" className="book-link" onClick={() => doMobileThing(websiteRo, "web-design")} />
                        <div id="poster-link" className="book-link" onClick={() => doMobileThing(posterRo, "poster")} />
                        <div id="book-cover-link" className="book-link" onClick={() => doMobileThing(bookCoverRo, "book-cover")} />
                        <div id="newspaper-link" className="book-link" onClick={() => doMobileThing(newspaperRo, "newspaper")} />
                        <div id="magazine-link" className="book-link" onClick={() => doMobileThing(magazineRo, "magazine")} />
                        <div id="photography-link" className="book-link" onClick={() => doMobileThing(photogRo, "photography")} />
                    </div>
                    <BookshelfRollover currentImage={thing} pathTo={pathTo}/>
                </div>
            </div>
        )
    }

    const DesktopBookshelf = () => {
        return (
            <div className="hide-under-720">
                <div id="desktop-bookshelf" className="bookshelf">
                    <div className="book-links-wrapper">
                        <Link id="photography-link" className="book-link" to="photography" onMouseEnter={() => doThing(photogRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="magazine-link" className="book-link" to="magazine" onMouseEnter={() => doThing(magazineRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="newspaper-link" className="book-link" to="newspaper" onMouseEnter={() => doThing(newspaperRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link> 
                        <Link id="book-cover-link" className="book-link" to="book-cover" onMouseEnter={() => doThing(bookCoverRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="poster-link" className="book-link" to="poster" onMouseEnter={() => doThing(posterRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="web-design-link" className="book-link" to="web-design" onMouseEnter={() => doThing(websiteRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="logos-link" className="book-link" to="logos" onMouseEnter={() => doThing(logoRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="advertisements-link" className="book-link" to="advertisements" onMouseEnter={() => doThing(adRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="found-stages-link" className="book-link" to="found-stages" onMouseEnter={() => doThing(foundStagesRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="woa-link" className="book-link" to="world-of-anarchie" onMouseEnter={() => doThing(woaRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                        <Link id="misc-link" className="book-link" to="misc" onMouseEnter={() => doThing(miscRo)} onMouseLeave={() => doOtherThing()}>
                            <div/>
                        </Link>
                    </div>
                    <BookshelfRollover currentImage={thing} />
                </div>
            </div>
        )
    }

    return (
        <>
        <SEO title="Graphic Design" />
        <div className="writing-content-wrap">
            <MothButtons size="small" order={[
                    {to: "/home", label:  "Home"},
                    {to: "/portfolio", label:  "Portfolio"}
                    ]}/>
            <MobileBookshelf />
            <DesktopBookshelf />
            <div className="preloaded-gd-images" id="adRo" />
            <div className="preloaded-gd-images" id="bookCoverRo" />
            <div className="preloaded-gd-images" id="foundStagesRo"/>
            <div className="preloaded-gd-images" id="logoRo"/>
            <div className="preloaded-gd-images" id="magazineRo"/>
            <div className="preloaded-gd-images" id="miscRo"/>
            <div className="preloaded-gd-images" id="newspaperRo"/>
            <div className="preloaded-gd-images" id="photogRo"/>
            <div className="preloaded-gd-images" id="posterRo"/>
            <div className="preloaded-gd-images" id="websiteRo"/>
            <div className="preloaded-gd-images" id="woaRo"/>
        </div>
        </>
    )
}
